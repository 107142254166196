<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Event Categories ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'event.category.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Category
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="category-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search category"
          @input="search"
        ></v-text-field>
        <!-- <template>
          <v-select
            class="ml-10"
            hide-details
            dense
            item-text="label"
            item-value="value"
            v-model="defaultFilter"
            :disabled="loading"
            :items="filterBy"
            style="max-width: 200px"
            @change="filterCategory"
          />

          <v-btn
            depressed
            class="ml-2"
            :disabled="loading"
            :color="sortOrder ? 'primary' : ''"
            @click="
              sortOrder = true
              filterCategory()
            "
          >
            <v-icon>{{ icons.arrowUp }}</v-icon>
          </v-btn>
          <v-btn
            depressed
            class="ml-2"
            :disabled="loading"
            :color="!sortOrder ? 'primary' : ''"
            @click="
              sortOrder = false
              filterCategory()
            "
          >
            <v-icon>{{ icons.arrowDown }}</v-icon>
          </v-btn>
        </template> -->
      </div>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="categories"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @update:options="fetchCategories"
        @pagination="handleScrollAfterTableRender"
        :items-per-page.sync="itemsPerPageTables"
      >
        <template v-slot:item.updated_at="{ item }">
          {{ item.updatedDate }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(categories.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>

      <!-- <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Category</th>
            <th class="text-left">Updated date</th>
            <th class="text-left">Added date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="category in categories"
            :key="category.id"
            class="clickable"
            @click="
              $router.push({
                name: 'event.category.details',
                params: { id: category.id },
              })
            "
          >
            <td>{{ category.label }}</td>
            <td>{{ category.updatedDate }}</td>
            <td>{{ category.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td v-for="index in 3" class="pl-0" :key="index">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(categories.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div> -->
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Event Category list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import FilterTableMixin from '@/utils/mixins/FilterTable'

export default {
  name: 'CategoriesPage',

  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'label',
      defaultStatus: true,
      sortOrder: false,
      filterBy: [
        {
          value: 'label',
          label: 'Category',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
        {
          value: 'updated_at',
          label: 'Updated date',
        },
      ],
      headers: [
        {
          text: 'Category',
          value: 'label',
        },
        {
          text: 'Updated date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      categories: (state) => state.category.list,
      listMeta: (state) => state.category.listMeta,
      permissions: (state) => state.auth.permissions,
      paramsCategory: (state) => state.category.queryParams,
      scrollPosition: (state) => state.category.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.EVENTS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'category/setQueryParams',
      setScrollPosition: 'category/setScrollPosition',
    }),

    fetchInitParams() {
      if (!this.paramsCategory) {
        this.fetchCategories()
        return
      }

      if (this.paramsCategory?.itemsPerPage) {
        const { itemsPerPage } = this.paramsCategory
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsCategory?.filter) {
        this.defaultStatus = this.paramsCategory.filter
      }

      if (this.paramsCategory?.sort) {
        if (this.paramsCategory.sort[0].includes('-')) {
          this.defaultFilter = this.paramsCategory.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsCategory?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'label'
      }

      if (this.paramsCategory?.search) {
        this.filter.search = this.paramsCategory.search
      }

      this.fetchCategories(null, {
        ...this.paramsCategory,
        type: CATEGORY_TYPE.event,
        paginate: true,
      })
    },

    search: debounce(function () {
      this.fetchCategories()
    }, 600),

    async fetchCategories(options, paramsCategory = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        paginate: true,
        type: CATEGORY_TYPE.event,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalItems
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalUsers
          : this.itemsPerPageTables,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearCategories()
      this.loading = true
      await this.getCategories(paramsCategory || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    editItem(category) {
      this.$router.push({
        name: 'event.category.details',
        params: { id: category.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
</style>
